import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils";
import { toast } from 'react-toastify';

export default function Vehicles({ activeTab }) {
    const [showVehicleModal, setShowVehicleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [vehicleInputData, setVehicleInputData] = useState({
        Name: '',
        registrationNumber: '',
        gpsId: '',
        isActive: true
    });
    const [vehicleIdToEdit, setVehicleIdToEdit] = useState(null);
    const [vehicleIdToDelete, setVehicleIdToDelete] = useState(null);
    const [vehiclesData, setVehiclesData] = useState();

    const fetchVehicleData = () => {
        getFromAPI("vehicle/").then(data => {
            if (data) {
                setVehiclesData(data); // Update the vehiclesData state
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error => {
            console.error('Error fetching vehicles:', error);
        });
    };

    
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (vehiclesData && !$.fn.dataTable.isDataTable("#vehicleDataTable")) {
            $("#vehicleDataTable").DataTable({
                dom: 'Bflrtip',
                pageLength: 25, // Set default entries per page to 25
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
                    }
                ],
                data: vehiclesData.map((data, i) => [
                    i + 1,
                    data.Name || 'N/A',
                    data.registrationNumber || 'N/A',
                    data.gpsId || 'N/A',
                    data.isActive
                        ? `<span class="badge badge-soft-success">Active</span>`
                        : `<span class="badge badge-soft-danger">Inactive</span>`,
                    `
                        <div class="d-flex">
                            <button class="btn btn-soft-primary table-btn me-2 edit-vehicle" data-vh-id="${data.id}">
                                <i class="bi bi-pencil-square"></i>
                            </button>
                            <button class="btn btn-soft-danger table-btn delete-vehicle" data-vh-id="${data.id}">
                                <i class="bi bi-trash3"></i>
                            </button>
                        </div>
                    `
                ]),
                columns: [
                    { title: "No." },
                    { title: "Vehicle Name" },
                    { title: "Vehicle Number" },
                    { title: "GPS ID" },
                    { title: "Status" },
                    { title: "Action" }
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });

            $("#vehicleDataTable").on('click', '.edit-vehicle', function () {
                const vehicleId = $(this).data('vh-id');
                handleShowVehicleModal("Edit Vehicle", vehicleId, true);
            });

            $("#vehicleDataTable").on('click', '.delete-vehicle', function () {
                const vehicleId = $(this).data('vh-id');
                setVehicleIdToDelete(vehicleId);
                setShowDeleteModal(true);
            });
        }
    }, [vehiclesData]);

    const updateVehicleDatatable = () => {
        const PODT = $('#vehicleDataTable').DataTable();
        getFromAPI("vehicle/")
            .then(response => {
                setVehiclesData(response);
                let DTData = response.map((data, i) => [
                    i + 1,
                    data.Name || 'N/A',
                    data.registrationNumber || 'N/A',
                    data.gpsId || 'N/A',
                    data.isActive
                        ? `<span class="badge badge-soft-success">Active</span>`
                        : `<span class="badge badge-soft-danger">Inactive</span>`,
                    `
                        <div class="d-flex">
                            <button class="btn btn-soft-primary table-btn me-2 edit-vehicle" data-vh-id="${data.id}">
                                <i class="bi bi-pencil-square"></i>
                            </button>
                            <button class="btn btn-soft-danger table-btn delete-vehicle" data-vh-id="${data.id}">
                                <i class="bi bi-trash3"></i>
                            </button>
                        </div>
                    `
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    const handleShowVehicleModal = (title, vehicleId = null, isEdit = false) => {
        setModalTitle(title);
        setShowVehicleModal(true);
    
        if (isEdit && vehicleId) {
            setVehicleIdToEdit(vehicleId);
    
            getFromAPI("vehicle/").then(data => {
                if (Array.isArray(data)) {
                    // Find the vehicle with the specified ID
                    const vehicleData = data.find(vehicle => vehicle.id === vehicleId);
    
                    if (vehicleData) {
                        setVehicleInputData({
                            Name: vehicleData.Name || '',
                            registrationNumber: vehicleData.registrationNumber || '',
                            gpsId: vehicleData.gpsId || '',
                            isActive: vehicleData.isActive || false
                        });
                    } else {
                        console.error('Vehicle not found in data:', vehicleId);
                    }
                } else {
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching vehicle:', error);
            });
        } else {
            setVehicleIdToEdit(null);
            setVehicleInputData({
                Name: '',
                registrationNumber: '',
                gpsId: '',
                isActive: true
            });
        }
    };

    const handleCloseModal = () => {
        setValidated(false);
        setShowVehicleModal(false);
        setVehicleIdToEdit(null);
        setVehicleInputData({
            Name: '',
            registrationNumber: '',
            gpsId: '',
            isActive: true
        })
    };

    const handleSaveVehicle = async (e) => {
        e.preventDefault(); // Prevent page reload
        const form = e.currentTarget;
        const formIsValid = form.checkValidity();

        // if (!vehicleInputData.Name || !vehicleInputData.registrationNumber || !vehicleInputData.gpsId || !formIsValid) {
        //     setValidated(true);
        //     toast.error('Vehicle Name and Registration Number and Gps id are required.', 'error');
        //     return;
        // }
    
        if (!formIsValid) {
            e.stopPropagation();
            setValidated(true);
            toast.error('vehicle Name and registration number are required.');
        } else {
            setValidated(true);

           

        try {
            let response;
            if (vehicleIdToEdit) {
                // Update existing vehicle
                response = await putToAPI(`vehicle/${vehicleIdToEdit}/`, vehicleInputData);
                if (response.status) {
                    toast.success("Vehicle updated successfully",'success')
                    updateVehicleDatatable();
                }
            } else {
                const isDuplicateName = vehiclesData.some(vehicle => {
                    return (
                        vehicle.Name.toLowerCase() === vehicleInputData.Name.toLowerCase() &&
                        (!vehicleIdToEdit || vehicle.id !== vehicleIdToEdit)
                    );
                });
                
                const isDuplicateRegistration = vehiclesData.some(vehicle => {
                    return (
                        vehicle.registrationNumber === vehicleInputData.registrationNumber &&
                        (!vehicleIdToEdit || vehicle.id !== vehicleIdToEdit)
                    );
                });
                
                const isDuplicateGpsId = vehiclesData.some(vehicle => {
                    return (
                        vehicle.gpsId === vehicleInputData.gpsId &&
                        (!vehicleIdToEdit || vehicle.id !== vehicleIdToEdit)
                    );
                });
                
                // Check for specific duplicates and show the corresponding error message
                if (isDuplicateName) {
                    toast.error('A vehicle with this name already exists.');
                    return;
                }
                
                if (isDuplicateRegistration) {
                    toast.error('A vehicle with this registration number already exists.');
                    return;
                }
                
                if (isDuplicateGpsId) {
                    toast.error('A vehicle with this GPS ID already exists.');
                    return;
                }
                // Add new vehicle
                response = await postToAPI("vehicle/", vehicleInputData);
                if (response.status) {
                    toast.success("Vehicle added successfully",'success')
                    updateVehicleDatatable();
                }
            }
            handleCloseModal();
         // Refresh vehicle data
        } catch (error) {
            console.error('Error:', error);
        }
    }
    };

    const handleConfirmDelete = async () => {
        try {
            let vehicleMapData = await getFromAPI(`vehicleMapping/?vehicleId=${vehicleIdToDelete}`);
            let vehicleAssignData = await getFromAPI(`VehicleAssignment/?vehicleId=${vehicleIdToDelete}`);
            // Delete Vehicle only if it is not mapped
            if(vehicleMapData.length === 0 && vehicleAssignData.length === 0) {
                await deleteFromAPI(`vehicle/${vehicleIdToDelete}/`);
                toast.success("Vehicle Deleted successfully",'success')
                setShowDeleteModal(false);
                updateVehicleDatatable();
            } else {
                toast.error("Selected vehicle is assigned in vehicle mapping. Make sure that vehicle is not assigned before delete.", 'error');
            }
        } catch (error) {
            console.error('Error deleting vehicle:', error);
            toast.error("Unexpected error",'error')
        }
    };

    const handleCheckboxChange = (event) => {
        setVehicleInputData({ ...vehicleInputData, isActive: event.target.checked });
    };

    useEffect(() => {
        if (activeTab == "vehicles")
        {
            fetchVehicleData();
        }
    }, [activeTab]);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Vehicles</Card.Title>
                <Button variant="primary" onClick={() => handleShowVehicleModal("Add Vehicle")}>
                    <i className="bi bi-plus-square me-2"></i> Add Vehicle
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="vehicleDataTable">
                <thead className="table-light">
                    <tr>
                        <th>No.</th>
                        <th>Vehicle Name</th>
                        <th>Vehicle Number</th>
                        <th>GPS ID</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </Table>

            {/* Vehicle Modal */}
            <Modal centered size="lg" show={showVehicleModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleSaveVehicle(e)}>
                <Modal.Body className="pb-0">
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="form-space">
                                <Form.Label>Vehicle Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter vehicle name..."
                                    name="vehiclename"
                                    value={vehicleInputData.Name || ''}
                                    onChange={(e) => setVehicleInputData({ ...vehicleInputData, Name: e.target.value })} required isInvalid={validated && !vehicleInputData.Name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter vehicle name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="form-space">
                                <Form.Label>Vehicle Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter vehicle number..."
                                    name="vehiclenumber"
                                    value={vehicleInputData.registrationNumber || ''}
                                    onChange={(e) => setVehicleInputData({ ...vehicleInputData, registrationNumber: e.target.value })} required isInvalid={validated && !vehicleInputData.registrationNumber}
                                />
                            <Form.Control.Feedback type="invalid">
                                    Please enter vehicle number.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="form-space">
                                <Form.Label>GPS ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter GPS ID..."
                                    name="gpsid"
                                    value={vehicleInputData.gpsId || ''}
                                    onChange={(e) => setVehicleInputData({ ...vehicleInputData, gpsId: e.target.value })} required isInvalid={validated && !vehicleInputData.gpsId}
                                />
                                <Form.Control.Feedback type="invalid">
                                        Please enter gps id.
                                    </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Form.Check
                                type="checkbox"
                                id="isActiveCheckbox"
                                label="Is Active"
                                checked={vehicleInputData.isActive || false} 
                                onChange={handleCheckboxChange} 
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="mt-1">
                    <Button variant="primary" type='submit'>Save</Button>
                    <Button variant="light" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>Delete</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
