import React, { useState, useEffect } from "react";
import { Card, Button, Table, Form, Modal, Col, Row } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils";
import { toast, ToastContainer } from "react-toastify";

export default function Zones({ activeTab }) {
    // Modal
    const [showZoneModal, setShowZoneModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [zoneData, setzoneData] = useState();
    const [wardData, setwardData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [EditZoneID, setEditZoneID] = useState();
    const [DeleteZoneID, setDeleteZoneID] = useState();

    // Form validation
    const [validated, setValidated] = useState(false);

    const [zoneInputData, setzoneInputData] = useState({
        name: "",
        code: "",
    })

    const FetchZoneData = () => {
        getFromAPI('zone/').then(data => {
            setzoneData(data);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const FetchWardData = () => {
        getFromAPI('ward/').then(data => {
            setwardData(data.ward);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const handleAddZone = async (e) => {
        e.preventDefault(); // Prevent page reload
        const form = e.currentTarget;
        const formIsValid = form.checkValidity();
    
        if (!formIsValid) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setValidated(true);
    
            
    
            // if (!zoneInputData.name || !zoneInputData.code) {
            //     toast.error('Please provide both Zone Name and Zone Code');
            //     return;
            // }
            
            try {
                let Response;
                if (isEdit) {
                    Response = await putToAPI(`zone/${EditZoneID}/`, zoneInputData);
                    if (Response.status) {
                        toast.success('Zone Successfully updated');
                    } else {
                        toast.error('Failed to update Zone');
                    }
                } else {
                    const isDuplicateName = zoneData.some(zone => {
                        return (
                            zone.name.toLowerCase() === zoneInputData.name.toLowerCase() &&
                            (!isEdit || zone.id !== EditZoneID)
                        );
                    });
                    
                    // Check if the zone code already exists
                    const isDuplicateCode = zoneData.some(zone => {
                        return (
                            zone.code.toLowerCase() === zoneInputData.code.toLowerCase() &&
                            (!isEdit || zone.id !== EditZoneID)
                        );
                    });
                    
                    // Show specific error messages for name or code duplicates
                    if (isDuplicateName) {
                        toast.error('A zone with this name already exists.');
                        return;
                    }
                    
                    if (isDuplicateCode) {
                        toast.error('A zone with this code already exists.');
                        return;
                    }
                    Response = await postToAPI("zone/", zoneInputData);
                    if (Response.status) {
                        toast.success('Zone Added Successfully');
                    } else {
                        toast.error('Failed to add Zone');
                    }
                }
                
                UpdateZoneDataTable(wardData);
                handleCloseZoneModal();
            } catch (error) {
                console.error('Error:', error);
                toast.error('An unexpected error occurred');
            }
        }
    };
    

    const HandleEditZone = (title, zone_id) => {
        // Fetch all zones to ensure we have the latest data
        getFromAPI("zone/").then(data => {
    
            // Check if the fetched data is an array
            if (Array.isArray(data)) {
                const numericZoneId = Number(zone_id); // Convert zone_id to number
                const zone_data = data.find(zone => zone.id === numericZoneId);
    
                if (zone_data) {
                    // Set the input data for the zone modal
                    setzoneInputData({
                        name: zone_data.name || '',
                        code: zone_data.code || ''
                    });
                    setEditZoneID(numericZoneId); // Set the ID for editing
                    handleOpenZoneModal(title); // Open the modal
                    setIsEdit(true); // Set the editing state
                } else {
                    console.error('Zone not found in data:', zone_id);
                }
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error => {
            console.error('Error fetching zones:', error);
        });
    };
    

    const handleOpenZoneModal = (title) => {
        setModalTitle(title);
        setShowZoneModal(true);
        // TODO: set supervisor data from zone_id if isEdit is true 
    };

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteZoneID(parseInt(id, 10)); // Ensure DeleteZoneID is a number
        setShowDeleteModal(true);
    };

    const handleDeleteZone = async () => {
        let hasAssignedWard = wardData.some((ward) => parseInt(ward.zone.id) === parseInt(DeleteZoneID));
        if (hasAssignedWard) {
            toast.error('This zone has wards assigned to it. Please remove or reassign these wards before attempting to delete the zone.');
        } else {
            try {
                const Response = await deleteFromAPI(`zone/${DeleteZoneID}/`);
                if (Response.status) {
                    toast.success('Zone Successfully deleted');
                    UpdateZoneDataTable(wardData);
                    handleCloseDeleteModal();
                } else {
                    toast.error('Failed to delete Zone');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An unexpected error occurred');
            }
        }
    }

    const handleCloseDeleteModal = () => {
        setDeleteZoneID(null);
        setShowDeleteModal(false);
    }

    const handleCloseZoneModal = () => {
        setIsEdit(false);
        setValidated(false);
        setShowZoneModal(false);
        setEditZoneID(null);
        setzoneInputData({
            name: "",
            code: "",
        });
    }

    // Datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (zoneData && wardData && !$.fn.dataTable.isDataTable("#zoneDataTable")) {
            $("#zoneDataTable").DataTable({
                dom: 'Bflrtip',
                pageLength: 25, // Set default entries per page to 25
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
                    }
                ],
                data: zoneData.map((data, i) => {
                    const zoneWards = wardData.filter(ward => ward.zone.id === data.id && !ward.isdeleted).map(ward => ward.name).join(', ');
                    return [
                        i + 1,
                        data.code,
                        data.name,
                        zoneWards,
                        data.id
                    ];
                }),
                columns: [
                    { title: "No." },
                    { title: "Zone Code" },
                    { title: "Zone Name" },
                    { title: "Wards" },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-zone" data-zone-id="${row[4]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-zone" data-zone-id="${row[4]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                select: true,
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#zoneDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-zone')) {
                    const zone_id = event.target.getAttribute('data-zone-id');
                    HandleEditZone("Edit Zone", zone_id);
                }
                if (event.target && event.target.classList.contains('delete-zone')) {
                    const zone_id = event.target.getAttribute('data-zone-id');
                    handleDeleteData(zone_id);
                }
            });

            // Ward filter logic
            $('#wardFilter').on('change', function () {
                const selectedWardID = $(this).val();
                const selectedWardZone = wardData.find((ward) => { return parseInt(ward.id) === parseInt(selectedWardID); });
                if (selectedWardZone) $("#zoneDataTable").DataTable().column(3).search(selectedWardZone.zone.name).draw();
                else $("#zoneDataTable").DataTable().column(3).search("").draw();
            });
        }
    }, [zoneData, wardData]);

    const UpdateZoneDataTable = (wardData) => {
        const PODT = $('#zoneDataTable').DataTable();
        getFromAPI("zone/")
            .then(response => {
                setzoneData(response);
                let DTData = response.map((data, i) => {
                    const zoneWards = wardData.filter(ward => ward.zone.id === data.id).map(ward => ward.name).join(', ');
                    return [
                        i + 1,
                        data.code,
                        data.name,
                        zoneWards,
                        data.id
                    ];
                });
                PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (activeTab == "zones")
        {
            FetchZoneData();
            FetchWardData();
        }
    }, [activeTab])

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Zones</Card.Title>
                <Button variant="primary" onClick={() => handleOpenZoneModal("Add Zone")}>
                    <i className="bi bi-plus-square me-2"></i> Add Zone
                </Button>
            </div>

            <Table responsive bordered id="zoneDataTable">
                <thead className="table-light"></thead>
            </Table>

            {/* Zone modal */}
            <Modal centered size="lg" show={showZoneModal} onHide={handleCloseZoneModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddZone(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Zone Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter zone name..." name="name" value={zoneInputData.name} onChange={(e) => setzoneInputData({ ...zoneInputData, name: e.target.value })} required isInvalid={validated && !zoneInputData.name} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter zone name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Zone Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter zone code..." name="code" value={zoneInputData.code} onChange={(e) => setzoneInputData({ ...zoneInputData, code: e.target.value })} required isInvalid={validated && !zoneInputData.code} />

                                    <Form.Control.Feedback type="invalid">
                                        Please enter zone code.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button type="submit" variant="primary">Save</Button>
                        <Button variant="light" onClick={handleCloseZoneModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleDeleteZone}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
