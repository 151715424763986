import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import 'datatables.net-dt/css/jquery.dataTables.css';
import { getFromAPI, postToAPI, deleteFromAPI } from '../Utils/utils'; // assuming these helper functions are defined
import { toast } from 'react-toastify';

export default function VehicleDetails({ vehicleDetailsData, onLoadingComplete }) {
  const [updatedVehicleDetails, setUpdatedVehicleDetails] = useState(vehicleDetailsData);
  const [storedUserType, setStoredUserType] = useState(localStorage.getItem('userType'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Initialize DataTable after data is updated
        if (!$.fn.dataTable.isDataTable("#vehicledetailsdatatable")) {
          $("#vehicledetailsdatatable").DataTable({
            dom: 'Bfrtip',
            pageLength: 25, // Set default entries per page to 25
            buttons: [
              {
                extend: 'excel',
                className: 'btn btn-export btn-md btn-lightdark',
                text: 'Export to Excel',
              },
              {
                extend: 'pdf',
                className: 'btn btn-export btn-md btn-lightdark',
                text: 'Export to PDF',
              },
              {
                extend: 'print',
                className: 'btn btn-export btn-md btn-lightdark',
                text: 'Print',
              },
            ],
            autoWidth: false,
            columnDefs: [{ targets: -1, orderable: false }],
            language: {
              search: '',
              searchPlaceholder: 'Search...',
              paginate: { previous: '«', next: '»' },
            },
          });
        }
      } catch (error) {
        console.error('Error updating vehicle details:', error);
      } finally {
        onLoadingComplete(); // Notify parent that loading is complete
      }
    };

    fetchData(); // Call the async function to fetch and update the data
  }, [vehicleDetailsData]);

  // Function to handle checkbox changes
  const handleCheckboxChange = async (vehicle, isChecked) => {
    try {
      if (isChecked) {
        let specificDate = vehicle.date_assigned;
        let currentTime = new Date().toTimeString().split(' ')[0]; // Get current time in HH:mm:ss format
        let scan_timestamp = `${specificDate}T${currentTime}.000Z`;
        // Post the data to qrScanLog
        await postToAPI('qrScanLog/', {
          scan_timestamp: scan_timestamp,
          issuccessful: 0,
          created_date: scan_timestamp,
          modified_date: scan_timestamp,
          destination_id: vehicle.destinationId,
          qrCodeId: vehicle.qrCodeId,
          vehicle_assignment_id: vehicle.vehicle_assignment_id,
        });
        const chel = document.getElementById("chkbox" + vehicle.qrCode)
        chel.checked = true;
        toast.success("Updated Successfully.")
        return true;
      } else {
        // Delete the data from qrScanLog
        const chel = document.getElementById("chkbox" + vehicle.qrCode)
        chel.checked = false;
        await deleteFromAPI(`qrscanlog/?vehicle_assignment_id=${vehicle.vehicle_assignment_id}&qrCodeId=${vehicle.qrCodeId}&isdeleted=0`); // Assuming qrCodeId is unique
        toast.success("Deleted Successfully.")
        return true;
      }
    } catch (error) {
      console.error('Error updating vehicle status:', error);
      toast.error("Error in updating")
      return false;
    }
  };
  
  return (
    <>
      <Table responsive bordered className="mb-0 table-nowrap" id="vehicledetailsdatatable">
        <thead className="table-light">
          <tr>
            <th>ID</th>
            <th>Date Assigned</th>
            <th>Destination</th>
            <th>QR Code</th>
            <th>Ward</th>
            <th>Zone</th>
            <th>Status</th>
            {storedUserType !== '3' && <th>Checkbox</th>}
          </tr>
        </thead>
        <tbody>
          {/* Render updated vehicle details */}
          {updatedVehicleDetails.length > 0 ? (
            updatedVehicleDetails.map((data, i) => (
              <tr key={i}>
                <td>{data.id}</td>
                <td>{data.date_assigned}</td>
                <td>{data.destination}</td>
                <td>{data.qrCode}</td>
                <td>{data.ward}</td>
                <td>{data.zone}</td>
                <td>
                  <span className={`badge badge-soft-${data.done ? "success" : "danger"}`}>
                    {data.done ? "Done" : "Pending"}
                  </span>
                </td>
                {storedUserType !== '3' && (
                  <td>
                    <input
                      type="checkbox"
                      id = {"chkbox" + data.qrCode}
                      checked={data.done} // Initialize checkbox based on `done`
                      onChange={(e) => {
                         const successfull =  handleCheckboxChange(data, e.target.checked);
                         if (successfull) data.done = e.target.checked;
                        }}
                    />
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                Loading...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
